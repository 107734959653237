.mainWrapper {
  padding: 1rem;
}

.stepsWrapper {
  display: flex;
  align-items: center;
}

.step {
  flex: 1 1 0px;
  cursor: pointer;
}

.bulletWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  background-color: #f4f4f4;
  flex-grow: 1;
  height: 2px;
}

.bullet {
  width: 8px;
  height: 8px;
  background-color: #eeeeee;
  margin: 0 8px;
  border-radius: 20px;
}

.stepLabel {
  color: #888888;
  padding: .5rem;
  text-align: center;
  display: block;
  margin-top: .25rem;
}

.submitButtonWrapper {
  margin-left: 3rem;
  display: flex;
  align-self: flex-end;
}
