.mainWrapper {
  display: flex;
}

.textsWrapper {
  margin-left: .5rem;
}

.textsWrapper > span {
  font-weight: 500;
}

.textsWrapper > span > small {
  font-weight: 400;
  display: block;
  color: #444444;
}

.submitter {
  font-weight: 400;
  display: block;
  color: #444444;
  margin-top: .5rem;
}