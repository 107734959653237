.sectionWrapperTitle {
  display: flex;
  justify-content: space-between;
}

.sectionWrapper+.sectionWrapper {
  margin-top: 1.5rem;
}

.sectionTitleWrapper {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.sectionTitleWrapper>span {
  font-weight: 500;
  color: #444444;
}

.sectionContentWrapper {
  padding-left: .75rem;
}

.flagRowWrapper {
  display: flex;
  font-size: .9rem;
}

.flagRowWrapper+.flagRowWrapper {
  margin-top: .75rem;
}

.flagRowWrapper>div {
  display: flex;
  align-items: center;
}

.flagIconWrapper {
  margin-right: 1rem;
}

.flagTextWrapper {
  flex-grow: 1;
}

.flagCountWrapper {
  margin-right: 1rem;
  font-weight: 500;
}

.confirmedMatchFoundLabel {
  color: #e84033;
  font-weight: 500;
  font-size: .9rem;
}

.potentialMatchFoundLabel {
  color: #f29000;
  font-weight: 500;
  font-size: .9rem;
}

.noMatchFoundLabel {
  color: #48a64c;
  font-weight: 500;
  font-size: .9rem;
}

.footerWrapper {
  margin-top: 1.25rem;
  display: flex;
}

.footerWrapper>div:first-child {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.footerWrapper>div:last-child {
  display: flex;
  align-items: flex-end;
}

.lastScanLabel {
  display: inline-block;
  font-size: .8rem;
  color: #444444;
}

.tooltipWrapper {
  margin-left: .25rem;
  flex-grow: 1;
}

.tooltipIcon {
  transform: scale(.7);
}

.tooltip {
  display: flex !important;
  align-items: center;
  margin-top: -.1rem;
}

.flagFooter {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1.25rem;
}

.sanctionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}