
.checkWrapper {
  display: inline-flex;
}

.checkInfoWrapper {
  display: flex;
  flex-direction: column;
}

.checkInfo {
  color: #9e9e9e;
}