.title {
  font-size: 1.3rem;
}

.caption {
  font-size: .85rem;
  color: #888888;
}

.link {
  cursor: "pointer";
  overflow: "hidden";
  white-space: "nowrap";
  text-overflow: "ellipsis";
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

.buttonWrapper>span,
.buttonWrapper button {
  width: 100%;
}

.clarificationAnswerItemWrapper {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
}

.clarificationAnswerItemWrapper>*+* {
  margin-left: .5rem;
}

.clarificationAnswerItemWrapper>span {
  cursor: pointer;
  color: #448aff;
}