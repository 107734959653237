.cardWrapper {
  margin-bottom: 1rem;
}

.itemWrapper {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.itemLabel {
  display: block;
  font-size: 0.75rem;
  color: #888888;
}

.itemValue {
  display: inline-flex;
  font-size: 0.85rem;
  margin-left: 0.5rem;
}

.blockItemValue {
  display: inline-flex;
  font-size: 0.85rem;
}

.reviewerWrapper {
  margin-bottom: 1rem;
}

.reviewerItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  column-gap: 0.5rem;
  align-items: center;
}

.reviewerName {
  min-width: 80px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
}

.caption {
  color: #888888;
  font-size: 0.75rem;
}

.captionText {
  color: #888888;
  font-size: 0.9rem;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}

.expandedSectionWrapper {
  padding: 1rem;
  border-radius: 5px;
  background-color: #f8f8f8;
  border: solid 1px #e8e8e8;
}

.singleItemWrapper {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.singleItemNameWrapper {
  display: flex;
  align-items: center;
}

.reviewItemNameWrapper > div + div {
  margin-left: 0.5rem;
}

.singleItemActionWrapper {
  text-align: right;
  flex-grow: 1;
}

.singleItemExpandWrapper {
  margin-right: 1rem;
}

.captionCount {
  color: #888888;
  font-size: 0.75rem;
}

.captionCount {
  display: flex;
  font-weight: 600;
  font-size: 0.85rem;
}

.captionCount > span:first-child {
  flex-grow: 1;
}

.caption {
  display: flex;
  margin-bottom: 0.75rem;
  font-weight: 600;
  font-size: 0.85rem;
}

.caption > span:first-child {
  flex-grow: 1;
}

.criteriaWrapper {
  border-top: double 3px #e8e8e8;
  border-bottom: double 3px #e8e8e8;
}

.criteriaWrapper > div + div {
  border-top: solid 1px #e8e8e8;
}

.criteriaItemWrapper {
  display: flex;
  align-items: center;
}

.criteriaItemWrapper > div,
.columnCriteriaItemWrapper > div {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}

.criteriaItemWrapper > div:first-child,
.columnCriteriaItemWrapper > div:first-child {
  flex-grow: 1;
  font-weight: 500;
}

.criteriaItemWeightWrapper {
  margin-left: 0.75rem;
  font-style: italic;
  color: #444444;
  font-size: 0.85rem;
  font-weight: 600;
}

.noteCriteriaItemTitle {
  margin-bottom: 0.75rem;
  font-weight: 500;
}

.additionalInformationWrapper {
  margin-top: 1rem;
}

.additionalInformationItemWrapper {
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
}

.additionalInformationItemWrapper > * + * {
  margin-left: 0.5rem;
}

.additionalInformationText {
  color: #333333;
}

.additionalInformationLabel {
  color: #777777;
}

.additionalInformationDescription {
  font-weight: 500;
}

.loader {
  text-align: center;
}
