.resultFormDeclinedMainWrapper {
  padding: 1rem;
  border-radius: 5px;
  background-color: #db516319;
}

.resultFormConfirmedMainWrapper {
  padding: 1rem;
  border-radius: 5px;
  background-color: #64BA1919;
}

.resultFormInputMainWrapper {
  padding: 1rem;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.resultFormTitleWrapper {
  margin-bottom: 1rem;
}

.resultFormTitleWrapper>span {
  font-size: .95rem;
  font-weight: 500;
}

.resultFormDeclinedWrapper {
  display: flex;
  align-items: center;
}

.resultFormDeclinedWrapper>span {
  display: inline-block;
  margin-left: .5rem;
  font-weight: 500;
  color: #5e1d26;
}

.resultFormConfirmedWrapper {
  display: flex;
  align-items: center;
}

.resultFormConfirmedWrapper>span {
  display: inline-block;
  margin-left: .5rem;
  font-weight: 500;
  color: #2d520d;
}

.resultFormInputWrapper {}

.notificationDateWrapper {
  margin-bottom: 1rem;
}

.notificationDateWrapper>span:first-child {
  display: inline-block;
  margin-right: .5rem;
}

.notificationDateWrapper>span:last-child {
  display: inline-block;
  font-weight: 500;
}

.resultFormActionWrapper {
  margin-top: 1.5rem;
  text-align: right;
}

.checkWrapper {
  display: inline-flex;
  margin-top: 1rem;
}

.checkInfoWrapper {
  display: flex;
  flex-direction: column;
}

.checkInfo {
  color: #9e9e9e;
}

.acceptLabel {
  display: block;
}

.checkFieldWrapper {
  margin-right: .5rem;
}

.checkActionWrapper {
  margin-top: .75rem;
}

.changeWrapper {
  text-align: right;
  margin-top: 1.5rem;
}