.headerWrapper {
  display: block;
}

.headerTitle {
  display: block;
}

.lastUpdateWrapper {
  font-size: .85rem;
  font-weight: normal;
  margin-top: .25rem;
  color: #444444;
}

.itemsWrapper {
  &> *:nth-child(odd):not(.itemWrapperChild) {
    background-color: #f8f8f8;
    border-radius: 4px;
  }
  a{
    padding: 0;
    &:hover{
      background-color: transparent;
    }
  }
}
.itemWrapper,.itemWrapperChild{
  padding: .5rem;
  display: flex;
  justify-content: space-between;
  &> span:first-child {
    flex-grow: 1;
    font-weight: 500;
    padding-right: 3rem;
  }
}

.itemWrapperChild {
  color: rgba(0, 0, 0, 0.54);
  background-color: white;
}

.link {
  color: #2196f3;
  cursor: pointer;
  text-decoration: none;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.subItemWrapper{
  display: block;
}