.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.contentWrapper {
  margin: 2rem auto;
}

.identityImage {
  border: 3px solid #707070;
  border-radius: 20px;
  overflow: hidden;
  text-align: center;
  padding: 1rem;
}

.identityImage > img {
  width: calc(95% - 2rem);
}