.sectionTitle {
  font-weight: 500;
  font-size: 1.1rem;
  display: block;
  color: #333333;
  margin-bottom: 2rem;
}

.sectionWrapper {
  padding: 0 .75rem;
}

.subSectionWrapper {
  padding: 0 0 0 .75rem;
}

.sectionWrapper+.sectionTitle {
  margin-top: 2rem;
}

.questionWrapper {
  display: flex;
}

.questionWrapper+.questionWrapper {
  margin-top: 1.5rem;
}

.questionWrapper>div:first-child {
  flex-grow: 1;
}

.questionWrapper>div:last-child {
  margin-left: 3rem;
  display: flex;
  justify-content: flex-end;
}

.agencyRatingWrapper {
  margin-left: 3rem;
  width: 300px;
  display: block !important;
}

.agencyBeneficiariesWrapper {
  margin-left: 3rem;
  width: 300px;
  display: flex;
  justify-content: flex-end;
}

.questionTitle {
  display: block;
  font-weight: 500;
  color: #333333;
}

.questionDescription {
  color: #555555;
  margin-top: .5rem;
  font-size: .85rem;
  display: block;
}

.questionFooterTitle {
  display: inline-block;
  margin-right: .25rem;
}

.questionFooterDescription {
  display: inline-block;
  margin-right: .25rem;
  font-weight: 500;
}

.guidanceLinkWrapper {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
}

.guidanceLinkWrapper>a {
  color: #777777;
  font-size: .85rem;
}

.guidanceLinkWrapper>*:last-child {
  margin-left: .5rem;
}

.firstQuestionUploadFormWrapper {
  margin-top: 1.5rem;
}

.firstQuestionUploadWrapper {
  margin-top: 1.5rem;
}

.firstQuestionFormWrapper {
  margin-top: 1.5rem;
  display: flex;
}

.firstQuestionFormWrapper>div {
  width: 30%;
}

.firstQuestionFormWrapper>div+div {
  margin-left: .75rem;
}

.firstQuestionFilesListWrapper {
  display: flex;
  margin-bottom: 1.5rem;
}

.firstQuestionFilesListWrapper>div {
  margin: 0 .5rem .5rem 0;
}

.mainActionWrapper {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.secondQuestionWrapper {
  margin: 1.5rem auto 1.5rem 2rem;
}

.secondQuestionUploadWrapper {
  margin-top: 1rem;
}

.subQuestionWrapper {
  margin-top: 1.5rem;
  padding-left: 1rem;
  display: flex;
}

.subQuestionWrapper>div:first-child {
  flex-grow: 1;
}

.secondQuestionSubWrapper {
  padding-left: 2rem;
}

.successMessageWrapper {
  flex-grow: 1;
}

.warningMessageWrapper {
  flex-grow: 1;
  margin-top: 2rem;
}

.sectionsWrapper>div+div {
  margin-top: 2rem;
}

.footerInfo {
  display: block;
  margin-bottom: 1rem;
  font-weight: 500;
  margin-top: 2rem;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  flex-grow: 1;
}

.actionButtons>span {
  display: block;
  margin-top: .5rem;
  font-size: .9rem;
  color: #333333;
}

.actionsWaring {
  display: block;
  margin-top: .5rem;
  color: #d32f2f;
}

.migratedMessageWrapper {
  margin-top: 2rem;
  flex-grow: 1;
}

.confirmMessageWrapper {
  margin-top: 2rem;
  flex-grow: 1;
}

.justificationsToProceedTitle {
  display: block;
  margin: 3rem 0 1rem 0;
  font-weight: 500;
}