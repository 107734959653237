
.languageSelectorWrapper {
  opacity: .75;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
}

.languageSelectorWrapper:hover {
  opacity: 1;
}

.languageSelectorWrapper>span {
  display: none;
}

.languageSelectorWrapper:hover>span {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  background-color: #33333399;
  border-radius: 5rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  align-items: center;
  justify-content: center;
}

.languageSelectorWrapper>div {
  margin: 0;
  padding: 0;
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 5rem;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
}

.languageSelectorWrapper>div>img {
  width: 100%;
  height: 100%;
}

.languageSelectorWrapperExpanded {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.languageSelectorWrapperExpanded>span {
  display: none;
}

.languageSelectorWrapperExpanded>div {
  margin: 0;
  padding: 0;
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 5rem;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.languageSelectorWrapperExpanded>div:hover {
  transform: scale(1.25);
  box-shadow: 0 1px 4px 0 #00000044;
}

.languageSelectorWrapperExpanded>div+div {
  margin-left: 1rem;
}

.languageSelectorWrapperExpanded>div>img {
  width: 100%;
  height: 100%;
}

.languageVisible {
  display: inline-flex;
}

.languageHidden {
  display: none !important;
}