.logoWrapper {
}

.logoContentWrapper {
  position: relative;
  height: 2.5rem;
}

.logo {
  height: 100%;
}

.logoBanner {
  position: absolute;
  bottom: -0.1rem;
  left: -0.2rem;
  background-color: #ffce20ee;
  color: #292209;
  padding: 0 0.2rem;
  border-radius: 4px;
  font-size: 0.5rem;
  font-weight: 600;
  font-family: monospace;
  cursor: help;
}

.bannerWrapper {
  flex-grow: 1;
  padding: 0 2.5rem;
}

.stackWrapper {
  display: inline-flex;
  align-items: center;
}

.stackWrapper > div {
  margin-left: 1rem;
}

.nameAndRole {
  padding: 0.15rem 0.12rem !important;
  height: auto !important;
  border-radius: 10rem !important;
}

.nameAbbreviation {
  height: 35px;
  width: 35px;
  line-height: 35px;
  border-radius: 50%;
  display: block;
  background-color: #ffffff;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
}

.username,
.role {
  display: block;
  color: #fff;
  padding: 0 0.5rem 0 0.25rem;
}

.username {
  font-size: 1rem;
  font-weight: 500;
  line-height: 0.95rem;
  padding-top: 0.5rem;
  text-transform: capitalize;
}

.role {
  font-size: 0.85rem;
}
