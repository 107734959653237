.questionsTableMainWrapper {
  margin-bottom: 1.5rem;
}

.questionsTableHeaderWrapper {
  display: flex;
}

.questionsTableHeader {
  text-align: center;
  width: 33%;
}

.questionsTableHeader>span {
  display: block;
  font-weight: 500;
  font-size: .9rem;
}

.questionsTableHeader>small {
  color: #555555;
}

.questionsTableRowsWrapper {
  border-top: solid 1px #dddddd;
  border-bottom: solid 1px #dddddd;
  margin-top: 1rem;
  padding: .5rem 0;
}

.questionsTableRow {
  display: flex;
}

.questionsTableCell {
  display: flex;
  justify-content: center;
  width: 33%;
  padding: 1rem 0;
}

.tooltipWrapper {
  display: inline-block;
  margin-left: 1rem;
}

.questionFieldsWrapper {
  max-width: 15rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.questionFieldsWrapper>*+* {
  margin-top: .5rem;
}

.questionsTableRowsWrapper>.questionsTableRow>.questionsTableCell:first-child {
  justify-content: flex-start;
}

.questionsTableHeaderWrapper>.questionsTableHeader:first-child {
  text-align: left;
}

.waringMessage {
  display: block;
  margin-top: .5rem;
  font-size: .85rem;
  width: 100%;
  color: #e46702;
}

.tooltipLink {
  color: inherit;
}

.warningMessageWrapper {
  margin-bottom: 2rem;
}