.sectionWrapper {
  margin: 1rem auto 1rem auto;
  background-color: #fcfcfc;
  border-radius: 5px;
  border: solid 1px #dddddd;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

.subSectionWrapper {
  padding-top: 0.5rem;
}

.subSectionPopulationWrapper {
}

.sectionLabel {
  display: block;
  font-weight: 500;
  margin: 1rem;
}

.itemLabel {
  display: block;
  font-size: 0.75rem;
  color: #888888;
  padding: 0.5rem auto 0.3rem auto;
}

.value {
  display: inline-flex;
  font-size: 0.85rem;
  padding-top: 0.3rem;
}

.icon {
  margin-right: 0.3rem;
}

.itemValue {
  display: block;
  font-size: 0.85rem;
}

.itemValueWrapper {
  margin: 0.3rem 0.3rem auto auto;
}

.itemPopulationValueWrapper {
  margin: 0.3rem;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.viewButton {
  display: flex;
  justify-content: flex-end;
}

.locationMapWrapper {
  padding-top: 1rem;
}

.attachmentsWrapper {
  margin-top: 0.5rem;
}
