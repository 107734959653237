.infoWrapper {
  display: block;
  margin-bottom: 0.5rem;
}

.infoTitle {
  font-weight: bold;
  margin-bottom: 1rem;
}

.infoText {
  color: inherit;
}

.alertTitle {
  font-weight: bold;
  margin-right: 0.5rem;
}

.modal {
  height: 26rem;
}

.tableWrapper {
  padding-top: 1rem;
}

.loaderWrapper {
  min-height: 19rem;
  align-items: center;
  display: flex;
  align-content: center;
  justify-content: center;
}
