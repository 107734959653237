.wrapper * {
  text-decoration: none !important;
  color: #444444;
}

.mainWrapper {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 50px;
  padding: .1rem .35rem;
  max-width: fit-content;
}

.mainWrapper * {
  display: flex;
  align-items: center;
}

.mainWrapper>div:first-child {
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 1.1rem;
  background-color: #dddddd;
  justify-content: center;
}

.mainWrapper>div+div {
  margin-left: .45rem;
}

.attachmentName {
  color: inherit;
}

.deleteIconWrapper {
  color: inherit;
  cursor: pointer;
}

.label {
  font-size: .75rem;
}