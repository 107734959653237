.submitButtonWrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 1rem;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}

.buttonWrapper div {
  margin: .5rem;
}