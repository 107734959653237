.mainWrapper {
  display: flex;
  border: solid 1px #dddddd;
  border-radius: 5px;
}

.filterWrapper {
  height: 3.5rem;
  padding: 0 .5rem;
  border-bottom: solid 1px #dddddd;
  display: flex;
  align-items: center;
}

.listWrapper {
  height: calc(100% - 3.5rem);
  overflow-y: auto;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}
