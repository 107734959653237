.updateButton {
  float: inline-end;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
