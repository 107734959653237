.sectionsWrapper>div+div {
  margin-top: 1rem;
}

.sectionWrapper>div+div {
  margin-top: .5rem;
}

.ratingLabel {
  text-transform: capitalize;
}

.dateLabel, .agencyLabel {
  display: block;
  font-size: .85rem;
  color: #555555;
  margin-top: .5rem;
}

.agencyValue {
  color: #333333;
  font-weight: 600;
}

.actionsWrapper {
  margin-top: 1.25rem;
  display: flex;
  justify-content: flex-end;
}

.title {
  font-weight: 500;
}

.expiredLabel {
  display: inline-block;
  color: #dd0000;
  margin-left: .5rem;
  font-weight: 500;
  font-size: .85rem;
}
