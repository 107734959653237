.captionWrapper {
  display: flex;
  flex-direction: column;
}

.label {
  color: #00000099;
  margin-bottom: .5rem;
}

.contentWrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1rem;
  margin: 2rem 0;
}

.headerWrapper {
  display: flex;
  align-items: center;
}

.headerContent {
  display: flex;
  flex-grow: 1;
}