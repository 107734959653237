.cardActionsWrapper {
  display: flex;
  justify-content: flex-end;
  padding: 0 .5rem .5rem .5rem;
  width: 100%;
}

.cardActionsWrapper>*:first-child {
  margin-right: .75rem;
}

.sectionTitleWrapper {
  display: flex;
  align-items: center;
}

.sectionTitleWrapper>span {
  margin-left: 1.5rem;
  display: block;
  font-weight: 500;
}

.sectionContentWrapper {
  padding: 1.5rem 2.3rem;
  border-left: #aaaaaa dotted 1px;
  margin-left: .70rem;
  width: 100%;
  min-width: 600px;
}

.sectionsWrapper>.sectionWrapper:last-child>.sectionContentWrapper {
  border-left: #00000000 dotted 1px;
}

.itemWrapper {
  display: flex;
}

.itemWrapper>div:first-child {
  flex-grow: 1;
  margin-right: 1.5rem;
}

.declarationWrapper>span {
  display: block;
  margin-bottom: .5rem;
  font-weight: 500;
  font-size: .85rem;
  color: #333333;
}

.declarationWrapper>div {
  display: flex;
}

.declarationItemWrapper+.declarationItemWrapper {
  margin-top: .5rem;
}

.declarationDateWrapper {
  margin-top: 1rem;
  display: flex;
  gap: .5rem;
  font-size: .85rem;
}

.declarationDateWrapper>span:first-child {
  display: block;
  margin-bottom: .5rem;
  font-weight: 500;
  font-size: .85rem;
  color: #333333;
}