.label {
  padding: .75rem;
  font-weight: 500;
  min-width: 20rem;
  text-align: left;
}

.subLabel {
  padding: .5rem;
  font-weight: 400;
  min-width: 20rem;
  text-align: left;
}

.value {
  padding: .75rem;
  text-align: center;
}

.flexValue {
  padding: .75rem;
  display: flex;
  text-align: center;
  justify-content: center;
}

.flagStatus {
  padding: 1rem;
  margin-right: .5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table {
  overflow-y: auto
}

.headerRow {
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.bodyRow {
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.subBodyRow {
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-left: 1rem;
}

.fullRating {
  font-weight: 500;
  color: #00aa00;
  border-radius: 8px;
  background-color: #00aa0014;
  padding: .2rem .45rem;
  font-size: .9rem;
}

.nonFullRating {
  font-weight: 500;
  color: #dd9900;
  border-radius: 8px;
  background-color: #dd990014;
  padding: .2rem .45rem;
  font-size: .9rem;
}

.lowRiskRating {
  font-weight: 500;
  color: #333333;
  border-radius: 8px;
  background-color: #cccccc44;
  padding: .2rem .45rem;
  font-size: .9rem;
}

.notEligibleRating {
  font-weight: 500;
  color: #dd0000;
  border-radius: 8px;
  background-color: #dd000014;
  padding: .2rem .45rem;
  font-size: .9rem;
}

.expiredLabel {
  display: inline-block;
  color: #dd0000;
  margin-left: .35rem;
  font-weight: 500;
  padding: .2rem;
}