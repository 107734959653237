.mainWrapper {
  background-color: #ffffff;
  border-bottom: solid 1px #e0e0e0;
}

.titleWrapper {
  display: flex;
  padding: 0 1.5rem;
  align-items: center;
  height: 4.5rem;
}

.titleWrapper > div:first-child {
  flex-grow: 1;
}

.title {
  font-size: 1.25rem;
  font-weight: 400;
  color: #333333;
}

.tabsWrapper {
  padding: 0 1.5rem;
}

.titleBackButtonWrapper {
  display: flex;
  align-items: center;
}

.backButtonWrapper {
  margin-right: .75rem;
}
