.footerWrapper {
  padding: .5rem 1rem;
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}

.logoNameWrapper {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}

.logoNameWrapper > img + div {
  margin-left: 1rem;
}

.nameLabel {
  display: block;
  font-size: .65rem;
  color: #555555;
}

.nameValue {
  display: block;
  font-size: .75rem;
  color: #333333;
  font-weight: 500;
}

.logo {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
}

.divider {
  height: .25rem;
}

.externalIconWrapper {
  display: flex;
  align-items: center;
}