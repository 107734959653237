.chipContent {
  text-transform: capitalize;
  margin-left: .5rem;
}

.headerWrapper {
  display: flex;
}

.headerContent {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.contentWrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1rem;
  margin: 2rem 0;
}