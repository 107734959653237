.warningIcon {
  width: 1.35rem;
  height: 1.35rem;
  border-radius: 8px;
  background-color: #dd000022;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .75;
}

.switchToHqButtonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
}

.loaderWrapper {
  text-align: center;
}