.headerWrapper {
  display: flex;
}

.headerContent {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.contentWrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1rem;
  margin: 2rem 0;
}

.ellipsisLabel {
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10rem;
}