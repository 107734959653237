.footerWrapper {
  display: flex;
}

.footerWrapper > div:first-child {
  flex-grow: 1;
}

.count {
  margin-bottom: .5rem;
}

.datePicker label{
  padding-right: 25px;
}