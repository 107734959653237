@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: fixed;
  bottom: 15px;
  left: 20px;
  z-index: 99999999;
}

.bannerWrapper {
  position: relative;

  width: 710px;
  height: 130px;
  padding: 1rem 1.2rem;
  background-color: #f8f8f8;
  box-shadow: 0 4px 8px 0 #00000077;
  border-radius: 8px;
  box-sizing: border-box;
}

.fraudBannerWrapper {
  position: relative;
  width: 710px;
  height: 150px;
  padding: 1rem 1.2rem;
  background-color: #f8f8f8;
  box-shadow: 0 4px 8px 0 #00000077;
  border-radius: 8px;
  box-sizing: border-box;
}

.bannerTopWrapper {
  display: flex;
  justify-content: space-between;
  height: 3.8rem;
}

.bannerBottomWrapper {
  display: flex;
  justify-content: flex-end;
}

.bannerFraudBottomWrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 1.5rem;
}

.bannerFraudWrapperVisible {
  transition: all 0.5s ease-in-out;
  opacity: 1;
  visibility: visible;
}

.bannerFraudWrapperHidden {
  transition: all 0.5s ease-in-out;
  opacity: 0;
  visibility: hidden;
  display: none;
}

.bannerWrapperVisible {
  transition: all 0.5s ease-in-out;
  opacity: 1;
  visibility: visible;
}

.bannerWrapperHidden {
  transition: all 0.5s ease-in-out;
  opacity: 0;
  visibility: hidden;
  display: none;
}

.textBold {
  color: #fefefe;
  text-shadow: 0 0 2px #000000;
  font-weight: 600 !important;
  font-family: "Roboto", sans-serif;
  font-size: 1rem !important;
  line-height: 1.2rem;
}

.text {
  color: #fefefe;
  text-shadow: 0 0 2px #000000;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 0.88rem;
  line-height: 1.2rem;
  display: inline-block;
}

.button {
  padding: 0.5rem 1.25rem;
  background-color: #ffce20;
  border: none;
  font-family: "Roboto", sans-serif;
  color: #2e2506;
  display: inline-block;
  font-weight: 900;
  font-size: 0.9rem;
  border-radius: 50px;
  box-shadow: 0 4px 8px 0 #000000aa;
  cursor: pointer;
  animation: shake 0.5s;
  transform: scale(1);
  transition: all 0.05s ease-in-out;
  user-select: none;
}

.button:hover {
  transform: scale(1.05);
  transition: all 0.05s ease-in-out;
}

.closeButtonWrapper {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: #00000033;
}
