.wrapper {
    margin: 1rem 0;
}

.contentWrapper {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: .5rem;
    padding: .1rem .5rem;
    margin: 0.5rem .5rem .5rem 0;
    width: 10rem;
}

.label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 5rem;
}