.itemContainer {
  background-color: rgba(139, 160, 179, 0.1);
  border-radius: .5rem;
  padding: .5rem;
  margin: .5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 1px -1px, rgba(0, 0, 0, 0.01) 0px 2px 1px 0px, rgba(0, 0, 0, 0.04) 0px 4px 1px 0px;
}

.item {
  cursor: pointer;
}

.title {
  font-weight: 600;
}