.mainWrapper {
  min-height: 100vh;
  background-size: cover;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 3rem;
  box-sizing: border-box;
}

.layoutWrapper {
  display: block;
  width: 1000px;
}

.boxWrapper {
  padding: 1.75rem;
  color: #222222;
  background-color: #ffffff;
  box-shadow: 0 20px 20px 0 #00000033;
  border-radius: 10px;
}

.headerWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.headerWrapper > div:first-child {
  margin-right: 1rem;
}

.title {
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
}

.subtitle {
  display: block;
  font-size: 0.85rem;
  margin-top: 0.25rem;
  color: #777777;
}

.formWrapper {
  padding: 0;
}

.sectionWrapper + .sectionWrapper {
  margin-top: 3rem;
}

.sectionContent {
  text-align: center;
}

.description {
  display: block;
  margin-bottom: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
  color: #444444;
  text-align: center;
}

.stepDescription {
  display: block;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #444444;
  text-align: left;
}

.firstDescription {
  font-size: 1.3rem;
}

.agencyFirstDescription {
  font-size: 1rem;
}

.firstDescription,
.agencyFirstDescription {
  display: block;
  margin-bottom: 1rem;
  font-weight: 500;
  color: #444444;
  text-align: left;
}

.actionsWrapper {
  margin-top: 1rem;
}

.actionsWrapper > * + * {
  margin-left: 1rem !important;
}

.sendActionsWrapper {
  margin-top: 1rem;
  display: flex;
  flex-direction: row-reverse;
}

.alertWrapper {
  margin-bottom: 1.5rem;
}

.stepWrapper {
  margin: 1.5rem 0;
}

.stackWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #ffffff;
  padding: 1rem 2rem;
}

.stackWrapper > div {
  margin-left: 1rem;
}

.logosWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
}

.unppLogoWrapper > img {
  width: 80px;
}

.agenciesLogosWrapper {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.errorMessage {
  color: #d32f2f;
  padding-left: 0.2rem;
}

.extraInfoWrapper {
  margin: 2rem 1rem;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", sans-serif;
}
