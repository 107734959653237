.cardActionsWrapper {
  display: flex;
  justify-content: flex-end;
  padding: 0 .5rem .5rem .5rem;
  width: 100%;
}

.cardActionsWrapper > *:first-child {
  margin-right: .75rem;
}

.sectionTitleWrapper {
  display: flex;
  align-items: center;
}

.sectionTitleWrapper > span {
  margin-left: 1.5rem;
  display: block;
  font-weight: 500;
}

.sectionContentWrapper {
  padding: 1.5rem 2.3rem;
  border-left: #aaaaaa dotted 1px;
  margin-left: .70rem;
  width: 100%;
  min-width: 200px;
}

.sectionsWrapper > .sectionWrapper:last-child > .sectionContentWrapper {
  border-left: #00000000 dotted 1px;
}

.itemWrapper {
  display: flex;
}

.itemWrapper > div:first-child {
  flex-grow: 1;
  margin-right: 1.5rem;
}