.mainWrapper {
  margin-top: 1rem;
}

.justificationWrapper>span {
  display: block;
  margin-bottom: .5rem;
}

.dateText {
  display: block;
  font-size: .9rem;
  color: #555555;
}

.justificationToProceed {
  font-family: inherit;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 600px;
}