.boxWrapper {
  margin: 1rem 0 1.5rem 0;
  background-color: #f8f8f8;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
}

.boxWrapperWithBackground {
  margin: 1rem 0 1.5rem 0;
  background: #f4f4f4;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
}

.boxTitleWrapper {
  border-bottom: solid 1px #dddddd;
  padding: 1rem;
  display: flex;
  align-items: center;
}

.boxTitle {
  font-weight: 500;
  font-size: 1rem;
}

.boxSections>.boxSection:first-child {
  border-top: none;
}

.boxSection {
  padding: 1rem;
  background-color: #f7f7f7;
}

.boxSection+.boxSection {
  border-top: solid 1px #dddddd;
}

.boxActions {
  border-top: solid 1px #dddddd;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.tooltipWrapper {
  margin-left: .5rem;
  display: flex;
  align-items: center;
}

.errorMessage {
  display: block;
  color: #d32f2f;
  font-size: .75rem;
}