.title {
  display: block;
  margin-bottom: .5rem;
  font-weight: 500;
}

.itemWrapper {
  display: flex;
  align-items: center;
  padding: .25rem 0;
}

.fieldsBullet {
  width: 1rem;
  height: 1rem;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
}

.fieldsHintLabel {
  display: inline-block;
  margin-left: .75rem;
}