.titleWrapper {
  display: flex;
  align-items: center;
  width: fit-content;
}

.title {
  display: block;
}

.typeWrapper {
  margin-left: 1rem;
  text-transform: capitalize;
  color: #f8f8f8;
}

.headerWrapper {
  display: flex;
  align-items: center;
}

.headerWrapper>*+* {
  margin-left: .5rem;
}

.buttonsWrapper {
  margin-left: 1rem;
}

.buttonsWrapper>*+* {
  margin-left: 1rem;
}

.statusWrapper {
  display: flex;
  align-items: center;
}

.statusTitleWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.statusTitleWrapper>span {
  display: inline-block;
  margin-left: .5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #333333;
}

.statusLabelWrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.statusLabelWrapper>span {
  display: block;
  margin-top: .05rem;
  color: #777777;
  font-size: .7rem;
}