.questionWrapper {
  padding: 1rem;
  border-radius: 5px;
  background-color: #fcfcfc;
  border: solid 1px #dddddd;
}

.questionWrapper {
  margin-top: 2rem;
}

.questionOptionWrapper {
  margin-bottom: 1rem;
}

.commentWrapper {

}

.confirmWrapper {
  margin-top: 4rem;
  padding: 1rem;
  border-radius: 5px;
  background-color: #fcfcfc;
  border: solid 1px #dddddd;
}

.additionalContentWrapper {
  margin-bottom: 1rem;
}

.observationWrapper {
  display: flex;
  flex-direction: column;
  border: solid 1px #dddddd;
  background-color: #f7f7f7;
  border-radius: 5px;
  padding: .75rem;
}

.observationWrapper + .observationWrapper {
  margin-top: 1rem;
}

.observationCommentWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  align-items: center;
}

.observationCommentWrapper > div + div {
  margin-left: 3rem;
}

.observationAdditionalWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.observationAdditionalWrapper > div + div {
  margin-left: 1.5rem;
}

.label {
  display: block;
  color: #555555;
  font-size: .75rem;
}

.value {
  display: block;
}

.flagsTitle {
  display: block;
  font-size: .95rem;
  font-weight: 500;
  color: #333333;
  margin-bottom: 1rem;
}
