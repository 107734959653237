.headerWrapper {
  display: block
}

.headerTitle {
  display: block;
}

.projectTypeWrapper {
  font-size: .85rem;
  font-weight: normal;
  margin-top: .25rem;
  color: #444444;
}

.projectIdWrapper {
  display: flex;
}

.projectIdLabel {
  font-size: .85rem;
  color: #444444;
}

.projectIdValue {
  font-size: .85rem;
  color: #444444;
}

.sectionLabel {
  display: block;
  font-weight: 500;
  margin: 1rem;
}

.itemLabel {
  display: block;
  font-size: .75rem;
  color: #888888;
  padding-top: 1rem;
}

.itemValue {
  display: block;
  font-size: .85rem;
}
