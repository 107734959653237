.item {
    vertical-align: middle;
    display: inline-flex;
}

.checkedIcon {
    color: #64BA19;
}

.notCheckedIcon {
    color: #DB5163;
}