.mainWrapper {
  border: solid 1px transparent;
  padding: .75rem 1.25rem .75rem .75rem;
  border-radius: 5px;
  display: flex;
}

.textWrapper {
  flex-grow: 1;
  padding-top: .05rem;
  font-size: 1rem;
}

.iconWrapper {
  filter: brightness(0.9);
  margin-right: .75rem;
}
