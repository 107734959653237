.informationText {
  font-weight: 400;
  display: block;
  color: #444444;
  margin: .5rem 0 1.5rem;
}

.tableWrapper {
  max-width: 80%;
}

.columnsHeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.columnsHeaderWrapper>div {
  display: flex;
  align-items: center;
}

.columnsHeaderWrapper>div:last-child {
  display: flex;
  justify-content: flex-end;
}

.columnsHeaderWrapper>div>span {
  font-weight: 500;
}

.tooltipWrapper {
  width: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.regionalOfficeWrapper+.regionalOfficeWrapper {
  margin-top: .5rem;
}

.regionalOfficeHeaderWrapper {
  display: flex;
  align-items: center;
}

.regionalOfficeHeaderArrowWrapper {
  width: 2.25rem;
}

.regionalOfficeHeaderNameWrapper {
  flex-grow: 1;
  padding: .5rem;
  font-weight: 500;
}

.regionalOfficeHeaderCountWrapper {
  display: flex;
  justify-content: center;
  margin-right: 1.5rem;
  padding: .75rem;
  font-weight: 500;
}

.regionalOfficeBodyWrapper {
  margin: .5rem 1.25rem .5rem 3rem;
  border: solid 1px #dddddd;
  border-radius: 5px;
}

.countryWrapper+.countryWrapper {
  border-top: solid 1px #dddddd;
}

.countryHeaderWrapper {
  display: flex;
  align-items: center;
  padding: .5rem;
}

.countryHeaderArrowWrapper {
  width: 2.25rem;
}

.countryHeaderNameWrapper {
  flex-grow: 1;
  padding: .5rem;
}

.countryHeaderCountWrapper {
  display: flex;
  justify-content: center;
  padding-right: .25rem;
  font-weight: 500;
}

.countryBodyWrapper>*:first-child {
  border-top: solid 1px #dddddd;
  font-size: .9rem;
}


.countryBodyWrapper>*:nth-child(odd) {
  background-color: #f8f8f8;
}

.countryBodyWrapper>*:nth-child(even) {
  background-color: #fefefe;
}

.partnerItemWrapper {
  display: flex;
  align-items: center;
  border-top: solid 1px #dddddd;
  padding: 0 10%;
}

.partnerItemCodeWrapper {
  padding: .5rem;
  min-width: 15rem;
  font-weight: 500;
}

.partnerItemNameWrapper {
  flex-grow: 1;
  padding: .5rem;
}

.partnerItemExpenditureWrapper {
  padding: .5rem;
  width: 33%;
  display: flex;
  justify-content: flex-end;
}