.expandedSectionWrapper {
  padding: 1rem;
  border-radius: 5px;
  background-color: #f8f8f8;
  border: solid 1px #e8e8e8;
}

.reviewItemWrapper {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.reviewItemNameWrapper {
  display: flex;
  align-items: center;
}

.reviewItemNameWrapper>div+div {
  margin-left: .5rem;
}

.reviewItemStatusWrapper {
  text-align: right;
  flex-grow: 1;
}

.reviewItemExpandWrapper {
  margin-right: 1rem;
}

.assessmentLabelWrapper {
  font-size: .85rem;
  color: #777777;
}

.assessmentLabelWrapper>span {
  display: inline-block;
}

.assessmentLabelWrapper>span+span {
  margin-left: .5rem;
}

.caption {
  display: flex;
  margin-bottom: .75rem;
  font-weight: 600;
  font-size: .85rem;
}

.caption>span:first-child {
  flex-grow: 1;
}

.criteriaWrapper {
  border-top: double 3px #e8e8e8;
  border-bottom: double 3px #e8e8e8;
}

.criteriaWrapper>div+div {
  border-top: solid 1px #e8e8e8;
}

.criteriaItemWrapper {
  display: flex;
  align-items: center;
}

.criteriaItemWrapper>div {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}

.criteriaItemWrapper>div:first-child {
  flex-grow: 1;
}

.criteriaItemWeightWrapper {
  margin-left: .75rem;
  font-style: italic;
  color: #444444;
  font-size: .85rem;
  font-weight: 600;
}

.additionalInformationWrapper {
  margin-top: 1rem;
}

.additionalInformationItemWrapper {
  margin-top: .75rem;
  display: flex;
  align-items: center;
}

.additionalInformationItemWrapper>*+* {
  margin-left: .5rem;
}

.additionalInformationText {
  color: #333333;
}

.additionalInformationLabel {
  color: #777777;
}

.totalScoreMainWrapper>div+div {
  margin-top: 1rem;
}

.totalScoreMainWrapper span {
  display: block;
  margin-bottom: .5rem;
}

.totalScoreMainWrapper small {
  display: block;
  color: #444444;
}