.snapshotsLabel {
  display: block;
  font-weight: 500;
}

.snapshotsWrapper {
  display: flex;
  margin-top: .5rem;
  margin-bottom: 1.5rem;
  max-width: 50%;
  flex-wrap: wrap;
}

.snapshotsWrapper>div {
  margin: 0 .5rem .5rem 0;
}

.footerWrapper {
  display: flex;
  align-items: flex-end;
  margin-top: 3rem;
}

.footerTitle {
  display: inline-block;
  margin-right: .5rem;
  color: #555555;
}

.footerDescription {
  display: inline-block;
  margin-right: .5rem;
  font-weight: 500;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  flex-grow: 1;
}

.actionButtons>span {
  display: block;
  margin-top: .5rem;
  font-size: .9rem;
  color: #333333;
}

.actionsWaring {
  display: block;
  margin-top: .5rem;
  color: #d32f2f;
}

.justificationsToProceedTitle {
  display: block;
  margin: 3rem 0 1rem 0;
  font-weight: 500;
}