.mainWrapper {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 1.15rem;
  align-items: center;
}

.mainWrapper>div:first-child {
  flex-grow: 1;
}

.contentWrapper {
  padding: 1.5rem 0;
}

.emptyLabel {
  color: #777777;
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.titleContent {
  display: flex;
  align-items: center;
}
.warningIcon {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 8px;
  background-color: #dd000022;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .75;
  margin-right: .5rem;
}

.warningWrapper {
  margin-left: 1rem;
  border-radius: 3rem;
  background-color: #ffce20;
  color: #be5702;
  padding: .15rem .5rem .15rem .75rem;
  font-size: .85rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  cursor: default;
  user-select: none;
}

.title {
  flex-grow: 1;
}