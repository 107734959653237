.stepDescription {
  display: block;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #444444;
  text-align: left;
}

.questionsWrapper {
  padding-left: 0.75rem;
}

.extraInfoWrapper {
  margin: 1.5rem 0;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", sans-serif;
}

.stackWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.warningContactsWrapper {
  margin-top: 1.5rem;
}

.warningContactsWrapper > span {
  display: block;
  margin-bottom: 0.5rem;
}

.warningContactItemWrapper {
  margin-top: 0.25rem;
}
