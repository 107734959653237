.notificationRowWrapper {
  padding: 1.5rem;
  border-left: solid 8px transparent;
}

.notificationRowWrapper + .notificationRowWrapper {
  border-top: dotted 1px #cccccc;
}

.notificationRowWrapper > div > b {
  display: block;
  margin-bottom: .5rem;
}

.notificationRowDescription {
  font-size: .85rem;
  color: #555555;
}

.notificationRowWrapper > div:last-child {
  display: flex;
  justify-content: flex-end;
}

.notificationRowWrapper > div > a {
  display: inline-flex;
  align-items: center;
  margin-top: .75rem;
  color: #2196f3;
  font-weight: 400;
  text-decoration: none;
}

.notificationRowProjectWrapper > span {
  display: block;
  margin-top: .75rem;
  font-size: .75rem;
  color: #aaaaaa;
}

.notificationRowDate {
  display: block;
  margin-bottom: .75rem;
  font-size: .75rem;
  color: #aaaaaa;
}

.notificationsHeaderWrapper {
  margin-bottom: 1.5rem;
}

.notificationsHeaderContentWrapper {
  padding: .75rem;
  display: flex;
}

.notificationsFiltersWrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.notificationsPaginationWrapper {
  display: flex;
  align-items: center;
}

.notificationsPaginationWrapper > div {
  display: flex;
  align-items: center;
  margin-left: .25rem;
}