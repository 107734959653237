.subscribeContainer {
  text-align: center;
}

.subscribeContainer .newSubscriptionBtn {
  padding: 1rem 3.5rem;
  text-transform: capitalize;
  font-size: 1rem;
}

.addTagsBtn {
  padding: 1rem 3.4rem;
}

.subscribeContainer .unsubscribeBtn {
  background: none;
  color: #2196f3;
}

.subscribeContainer .unsubscribeBtn,
.subscribeContainer .unsubscribeBtn:hover {
  border: none;
  box-shadow: none;
  margin-top: 3rem;
  text-transform: capitalize;
}

.subscribeContainer .unsubscribeBtn:hover {
  background: #2196f3;
  color: #fff;
}

.tagsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag {
  display: inline-block;
  border-radius: 3rem;
  margin-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 12px;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
  background: #d9d9d9;
}
