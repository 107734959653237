@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

.mainWrapper {
  min-height: 100vh;
  min-width: 950px;
  background-size: cover;
  font-family: "Lato", sans-serif;
}

.headerWrapper {
  padding: 0.75rem;
  display: flex;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 7.5rem);
  flex-direction: row;
}

.columnsWrapper {
  display: flex; /* Use flexbox */
  flex-direction: row; /* Set row layout */
}

.columnsWrapper > div:first-child {
  width: 100%; /* Use full width on smaller screens */
  max-width: 400px; /* Limit the maximum width */
  margin-right: 6rem; /* Margin for spacing */
}

@media (max-width: 768px) {
  /* Adjust layout for smaller screens */
  .contentWrapper {
    align-items: center;
    flex-direction: column; /* Change to column layout */
    height: auto; /* Allow height to adjust */
  }

  .columnsWrapper > div:first-child {
    margin-right: 10; /* Remove margin on smaller screens */
  }
}

@media (max-width: 480px) {
  /* Further adjustments for smaller screens */
  .contentWrapper {
    padding: 1rem; /* Add padding for spacing */
  }

  .columnsWrapper {
    flex-direction: column; /* Ensure column layout */
    align-items: center; /* Center content */
  }
}

.loginWrapper {
  padding: 2.5rem 1.5rem; /* Padding for spacing */
  color: #222222; /* Text color */
  background-color: #ffffff; /* Background color */
  box-shadow: 0 20px 20px 0 #00000033; /* Box shadow for depth */
  border-radius: 10px; /* Rounded corners */
}

.title {
  display: block;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.subtitle {
  display: block;
  text-align: center;
  margin-bottom: 3.5rem;
  color: #444444;
}

.formWrapper {
  margin: 0 auto;
  width: 75%;
}

.formLabel {
  display: block;
  margin-bottom: 0.5rem;
}

.formField {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 100%;
  padding: 0.5rem;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  font-weight: 300;
}

.formFieldWithError {
  background: #ffffff;
  border: 1px solid #cc0000;
  border-radius: 5px;
  width: 100%;
  padding: 0.5rem;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  font-weight: 300;
}

.errorMessage {
  display: block;
  height: 2rem;
  margin-bottom: 1.5rem;
  color: #cc0000;
}

.formButton {
  border: none;
  border-radius: 5px;
  background-color: #2296f3;
  color: #ffffff;
  padding: 0.5rem;
  font-family: "Lato", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.formButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.formButton:hover {
  filter: brightness(110%);
}

.formButton:active {
  filter: brightness(120%);
}

.formButton > span {
  margin-right: 0.5rem;
  font-size: 1rem;
  padding-bottom: 0.15rem;
}

.formButtonHelpText {
  display: block;
  height: 2rem;
  margin-bottom: 2.5rem;
  color: #888888;
  text-align: center;
  font-weight: 300;
}

.register {
  display: block;
  margin: 0 auto;
  color: #2296f3;
  text-align: center;
  cursor: pointer;
}

.register:hover {
  filter: brightness(110%);
}

.register:active {
  filter: brightness(120%);
}

.description {
  margin: 1rem 0 0.5rem 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  color: #fefefebb;
  width: 40vw;
}

.learnMoreLink {
  display: block;
  text-decoration: none;
  font-family: "Lato", sans-serif;
  color: #fcc30b;
  margin-bottom: 2.5rem;
  cursor: pointer;
}

.learnMoreLink:hover {
  filter: brightness(110%);
}

.learnMoreLink:active {
  filter: brightness(120%);
}

.directLoginMainWrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
}

.directLoginContentWrapper {
  width: 600px;
  height: 300px;
}

.backButtonWrapper {
  color: #ffffff;
}

.agenciesLogosWrapper {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  flex-wrap: wrap; /* Allow content to wrap when necessary */
  justify-content: center; /* Center align when content wraps */
  height: 40px; /* Keep the same height */
}

@media (max-width: 768px) {
  /* Adjust for smaller screens */
  .agenciesLogosWrapper {
    gap: 1rem; /* Reduce the gap on smaller screens */
  }
}

@media (max-width: 480px) {
  /* Further adjust for even smaller screens */
  .agenciesLogosWrapper {
    gap: 0.5rem; /* Further reduce the gap */
  }

  .agenciesLogosWrapper img {
    max-width: 80px; /* Limit image width for smaller screens */
  }
}
