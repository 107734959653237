.titleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  padding: .3rem .6rem;
}

.progressWrapper {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleWrapper {
  display: flex;
  align-items: center;
  height: 3.5rem;
  padding: 0 1rem;
  border-bottom: solid 1px #e0e0e0;
}

.titleWrapper>div:first-child {
  flex-grow: 1;
}

.borderedTable {
  border: solid 1px rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  width: 100%;
  margin-bottom: 0px;
  background-color: #f8f8f8;
}