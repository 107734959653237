.itemsMainWrapper {
  margin-top: 1rem;
}

.itemsMainWrapper>.itemWrapper:nth-child(odd) {
  background-color: #f4f4f4;
  border-radius: 5px;
}

.itemWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.itemWrapper>div {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 1rem 1.5rem;
}

.itemWrapper>div:first-child {
  flex-grow: 1;
  font-weight: 500;
}