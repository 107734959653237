.wrapper {
  padding: 1rem 0;
  text-align: center;
}

.fullScreenWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 80vh;
  position: fixed;
  z-index: 9999999;
  text-align: center;
}