.submitButtonWrapper {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1rem;
}

.infoWrapper {
  display: flex;
  align-items: center;
}

.infoLabel {
  color: #888888;
  font-size: .75rem;
  display: block;
}

.infoValue {
  display: block;
  font-size: .85rem;
  padding: 0 0.5rem;
  margin-right: 0.5rem;
  border-right: solid 1px #dddddd;
}

.dateValue {
  font-size: .75rem;
}