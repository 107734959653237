@import url("https://api.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.css");
html {
  height: 100%;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background-color: #f4f4f4 !important;
}

#atlwdg-trigger {
  background: linear-gradient(228.3deg, #488dcb 16.88%, #6b5ea6 73.56%);
  color: #f8f8f8 !important;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: none !important;
  bottom: 0 !important;
  right: 180px !important;
  z-index: 999;
  font-size: 0.85rem;
  margin: 0.95rem 0.5rem;
  font-family: system-ui !important;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 2px 0 #00000044;
}
