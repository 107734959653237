.caption {
  display: flex;
  margin-bottom: .75rem;
  font-weight: 600;
  font-size: .85rem;
}

.caption>span:first-child {
  flex-grow: 1;
}

.criteriaWrapper {
  border-top: double 3px #e8e8e8;
  border-bottom: double 3px #e8e8e8;
}

.criteriaWrapper>div+div {
  border-top: solid 1px #e8e8e8;
}

.criteriaItemWrapper {
  display: flex;
  align-items: center;
}

.criteriaItemWrapper>div {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}

.criteriaItemWrapper>div:first-child {
  flex-grow: 1;
}

.criteriaItemWrapper>div>div:first-child {
  width: 180px;
}

.criteriaItemWeightWrapper {
  margin-left: .75rem;
  font-style: italic;
  color: #444444;
  font-size: .85rem;
  font-weight: 600;
}