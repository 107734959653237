.submitButtonWrapper {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1rem;
}

.sectionWrapper {
  background-color: #e7e7e7;
  border-radius: 5px;
  border: solid 1px #dddddd;
  padding: .5rem;
  display: flex;
  flex-direction: column;
}

.sectionTitle {
  color: #555555;
  font-weight: bold;
  font-size: .9rem;
}

.listWrapper {
  list-style: none;
  padding-left: .5rem;
}

.guidanceLinkWrapper {
  display: flex;
  align-items: center;
}

.guidanceLinkWrapper>a {
  color: #777777;
  font-size: .85rem;
  padding-left: .3rem;
}

.guidanceLinkWrapper>*:last-child {
  margin-left: .5rem;
}