.itemWrapper {
  margin-bottom: 1.5rem;
}

.itemColumnsWrapper {
  display: flex;
  align-items: center;
  padding-right: .5rem;
}

.itemColumnsWrapper>div+div {
  margin-left: 1.5rem;
}

.title {
  flex-grow: 1;
  font-weight: bold;
}

.warningIconWrapper {
  width: 1.35rem;
  height: 1.35rem;
  margin-left: 1.5rem;
}

.warningIcon {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: #dd000022;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .75;
}