.vendorNumber {
  font-weight: 600;
}

.requestedVendorNumber {
  color: #333333;
}

.missingVendorNumber {
  font-style: italic;
  color: #888888;
}

.vendorNumberActionsWrapper {
  margin-top: 1rem;
}

.vendorNumberActionsTitle {
  font-weight: 500;
  display: block;
}

.vendorNumberActionsSubTitle {
  display: block;
  margin-bottom: 1rem;
}

.vendorNumberActionsColumnsWrapper {
  display: flex;
}

.vendorNumberActionsColumnsWrapper>div {
  margin-right: 1rem;
}

.vendorNumberRequestError {
  display: block;
  margin-top: 1rem;
  color: #cc0000;
}

.modalActionsWrapper {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.modalActionsWrapper > div {
  margin-left: .5rem;
}

.displayLabel {
  display: block;
  font-weight: 500;
}

.displayValue {
  margin-bottom: .5rem;
  display: block;
}

.breadcrumbWrapper {
  margin-bottom: 1.5rem;
}

.partnerUnDocumentsWrapper {
  margin-top: 1.5rem;
}

.partnerUnDocumentsHeader {
  display: flex;
  justify-content: space-between;
}

.partnerVendorNumberLinkFormFieldWrapper {
  display: flex;
  align-items: flex-end;
  box-shadow: 0 1px 3px 0 #00000033;
  padding: 1rem;
  width: max-content;
}

.partnerVendorNumberLinkFormField {
  width: 30rem;
}

.partnerVendorNumberLinkMissing {
  color: #aaaaaa;
  font-style: italic;
  margin: auto .5rem;
}

.partnerVendorNumberLinkValueWrapper {
  display: flex;
  align-items: center;
}

.partnerVendorNumberLinkValueLabel {
  font-weight: bold;
  display: inline-block;
}

.partnerVendorNumberLinkValue {
  color: #448aff;
  text-decoration: none;
}

.vendorNumberLinkIcons {
  margin: auto .5rem;
  cursor: pointer;
}

.vendorNumberLinkIcon {
  margin-right: .5rem;
}

.contentWrapper {
  display: flex;
}

.label {
  font-weight: bold;
  display: inline-block;
  margin-right: .75rem;
}

.value {
  color: #448aff;
  text-decoration: none;
}

.error {
  color: #aaaaaa;
  font-style: italic;
}

.externalDataWrapper {
margin-top: 1rem;
}

.externalDataLoaderWrapper {
  text-align: center;
}