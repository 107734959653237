.contentWrapper {
  display: flex;
  align-items: center;
}

.contentWrapper > .labelWrapper {
  margin-right: .5rem;
}

.contentWrapperBlock {
  display: block;
}

.contentWrapperBlock > .labelWrapper {
  margin-bottom: .5rem;
}

.optionsWrapper {
  display: flex;
}

.blockOptionsWrapper {
  display: flex;
  flex-direction: column;
}

.optionWrapper {
  display: flex;
  align-items: center;
}

.blockOptionWrapper {
  display: inline-flex;
  align-items: center;
}

.optionWrapper > div {
  display: flex;
  align-items: center;
}

.tooltipWrapper {
  margin-left: .35rem;
}

.optionLabel {
  font-size: .8rem;
}

.labelTooltipWrapper {
  margin-left: .25rem;
}

.extraLabelText {
  font-size: .75rem;
}

.extraLabelWrapper {
  margin-bottom: .5rem;
}