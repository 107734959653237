.formWrapper {
  margin-top: 2rem;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}


.sectionWrapper {
  margin: 1rem auto 1rem auto;
  background-color: #fcfcfc;
  border-radius: 5px;
  border: solid 1px #dddddd;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.sectionWrapper + .sectionWrapper { margin-top: 1rem }

.infoWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.info {
  font-weight: 500;
  font-size: .85rem;
}

.secondaryInfo {
  font-size: .75rem;
  color: #0000008A;
}

.text {
  font-size: .85rem;
  font-weight: 400;
  display: block;
  color: #444444;
  margin-top: 0.5rem;
}