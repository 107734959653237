.mapWrapper {
  border-radius: 3px;
  overflow: hidden;
  border-width: 3px;
  border-style: solid;
  border-color: transparent;
}

.popupWrapper {
  padding: 1rem .5rem;
  display: flex;
  align-items: center;
}

.popupWrapper > div:last-child {
  margin-left: 1.5rem;
}

.deleteLabel {
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
}

.searchWrapper {
  margin-bottom: 1rem;
}

.errorText {
  color: #d32f2f;
  font-size: 0.75rem;
  margin: 3px 14px 0px;
}
