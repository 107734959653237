.infoWrapper {
  display: block;
  margin-bottom: .5rem;
}

.infoTitle {
  font-weight: bold;
  margin-bottom: 1rem;
}

.infoText {
  color: inherit;
}

.alertTitle {
  font-weight: bold;
  margin-right: .5rem;
}
