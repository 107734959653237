.headerActionWrapper {
  margin-right: 8px;
}

.timelineWrapper {
  position: relative;
  height: 12rem;
}

.timelineRow {
  position: absolute;
  height: 8px;
  border-radius: 5px;
  background-color: #eeeeee;
  width: 100%;
  top: 4.9rem;
}

.timelineProgressRow {
  position: absolute;
  height: 8px;
  border-radius: 5px;
  background: repeating-linear-gradient(45deg, #cfcfcf, #cfcfcf 7px, #dfdfdf 0, #dfdfdf 14px);
  width: 100%;
  top: 4.9rem;
}

.timelineItemWrapperOne {
  width: 5.5rem;
  height: 4rem;
  position: absolute;
  top: 5.5rem;
  display: inline-block;
}

.timelineItemWrapperOne>.bullet {
  position: absolute;
  top: -20px;
  left: 0;
  clip-path: polygon(50% 0%, 100% 35%, 100% 100%, 0 100%, 0% 35%);
}

.timelineItemWrapperOne>.paper {
  position: absolute;
  top: -80px;
}

.timelineItemWrapperTwo {
  width: 5.5rem;
  height: 4rem;
  position: absolute;
  top: 5.5rem;
  display: inline-block;
}

.timelineItemWrapperTwo>.bullet {
  position: absolute;
  top: -12px;
  left: 0;
  clip-path: polygon(0 0, 100% 0, 100% 65%, 50% 100%, 0 65%);
}

.timelineItemWrapperTwo>.paper {
  position: absolute;
  top: 5px;
  left: -2rem;
}

.timelineItemWrapperThree {
  width: 5.5rem;
  height: 4rem;
  position: absolute;
  top: 5.5rem;
  display: inline-block;
}

.timelineItemWrapperThree>.bullet {
  position: absolute;
  top: -20px;
  left: 0;
  clip-path: polygon(50% 0%, 100% 35%, 100% 100%, 0 100%, 0% 35%);
}

.timelineItemWrapperThree>.paper {
  position: absolute;
  top: -80px;
  left: -2rem;
}

.timelineItemWrapperFour {
  width: 5.5rem;
  height: 4rem;
  position: absolute;
  top: 5.5rem;
  display: inline-block;
}

.timelineItemWrapperFour>.bullet {
  position: absolute;
  top: -12px;
  left: 0;
  clip-path: polygon(0 0, 100% 0, 100% 65%, 50% 100%, 0 65%);
}

.timelineItemWrapperFour>.paper {
  position: absolute;
  top: 5px;
  left: -2rem;
}

.timelineItemWrapperFive {
  width: 5.5rem;
  height: 4rem;
  position: absolute;
  top: 5.5rem;
  display: inline-flex;
  justify-content: flex-end;
}

.timelineItemWrapperFive>.bullet {
  position: absolute;
  top: -20px;
  right: 0;
  clip-path: polygon(50% 0%, 100% 35%, 100% 100%, 0 100%, 0% 35%);
}

.timelineItemWrapperFive>.paper {
  position: absolute;
  top: -80px;
}

.bullet {
  width: 10px;
  height: 20px;
  border-radius: 5px;
  position: absolute;
  display: inline-block;
}

.paper {
  box-shadow: 0 1px 3px 0 #00000044;
  width: 5rem;
  font-size: .75rem;
  font-weight: 500;
  color: #333333;
  margin-top: 1rem;
  text-align: center;
  border-radius: 5px;
  background-color: #ffffff;
  padding: .25rem;
  box-sizing: border-box;
  border-left-width: 0px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 3px;
  border-style: solid;
  user-select: none;
}

.paper:hover {
  z-index: 9;
  transform: scale(1.05);
}

.legendWrapper {}

ul.legend {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul.legend>li {
  margin: 0;
  padding: 0 2rem 0 0;
  display: inline-flex;
  align-items: center;
  font-size: .8rem;
  color: #333333;
}

ul.legend>li>div {
  width: 15px;
  height: 15px;
  border-radius: 50px;
  margin-right: .5rem;
}