.mainWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxWrapper {
  width: 70vw;
}

.smallBoxWrapper {
  width: 45vw;
}

.headerWrapper {
  padding: 1.5rem 1.5rem 0rem 1.5rem;
  display: flex;
  align-items: center;
}

.headerWrapper>div:first-child {
  flex-grow: 1;
}

.contentWrapper {
  max-height: 70vh;
  padding: 1.5rem;
  overflow-x: auto;
  box-sizing: border-box;
}

.smallContentWrapper {
  max-height: 50vh;
  padding: 1.5rem;
  overflow-x: auto;
  box-sizing: border-box;
}

.footerWrapper {
  padding: 1.5rem;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
}

.customFooterWrapper {
  padding: 1.5rem;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.footerWrapper > * {
  margin-left: 1rem !important;
}

@media screen and (max-width: 1200px) {
  .boxWrapper {
    width: 80vw;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.titleIconWrapper {
  margin-right: .65rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: .55rem;
}

.titleWrapper span {
  font-size: 1.1rem;
  font-weight: 500;
  color: #444444;
}