.contentWrapper {
  display: flex;
}

.button {
  justify-content: flex-end;
}

.info {
  flex-grow: 1;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
}

.cardTitle {
  flex-grow: 1;
}