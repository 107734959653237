.tabsWrapper {
  border-bottom: solid 1px #dddddd;
}


.titleWrapper {
  display: flex;
  align-items: center;
}

.warningIcon {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 8px;
  background-color: #dd000022;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .75;
  margin-right: .5rem;
}

.circleIcon {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .75;
}