.footerWrapper {
  display: flex;
}

.footerWrapper > div:first-child {
  flex-grow: 1;
}

.count {
  margin-bottom: .5rem;
}

.tooltipWrapper {
  margin-right: .5rem;
  display: flex;
  align-items: center;
}