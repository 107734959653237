.resultFormDeclinedMainWrapper {}

.resultFormConfirmedMainWrapper {
  padding: 1rem;
  border-radius: 5px;
  background-color: #64BA1919;
}

.resultFormInputMainWrapper {
  padding: 1rem;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.resultFormTitleWrapper {
  margin-bottom: 1rem;
}

.resultFormTitleWrapper>span {
  font-size: .95rem;
  font-weight: 500;
}

.resultFormDeclinedWrapper {}

.resultFormConfirmedWrapper {
  display: flex;
  align-items: center;
}

.resultFormConfirmedWrapper > span{
  display: inline-block;
  margin-left: .5rem;
  font-weight: 500;
  color: #2d520d;
}

.resultFormInputWrapper {}

.notificationDateWrapper {
  margin-bottom: 1rem;
}

.notificationDateWrapper>span:first-child {
  display: inline-block;
  margin-right: .5rem;
}

.notificationDateWrapper>span:last-child {
  display: inline-block;
  font-weight: 500;
}

.resultFormActionWrapper {
  margin-top: 1rem;
  text-align: right;
}