.banner {
  background-color: #ffce20;
  height: 2.85rem;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  color: #111111;
  box-shadow: 0 3px 3px -2px #00000077;
  overflow: hidden;
}

.bannerIconWrapper {
  background-color: #cc000000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  width: 1.75rem;
  margin-left: .5rem;
  border-radius: 10px;
  color: #cc0000;
  padding: 0 .5rem;
  font-size: .8rem;
  font-weight: 500;
}

.bannerTextWrapperCurrent {
  padding: 0 .5rem;
  font-size: .79rem;
  line-height: 1.05rem;
}

.title {
  display: inline-block;
  margin-right: .45rem;
  padding: 0 .35rem;
  background-color: #cc000022;
  color: #cc0000;
  border-radius: 5px;
  font-weight: 600;
  font-size: .65rem;
}