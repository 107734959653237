.contentWrapper {
  display: flex;
  justify-content: center;
}

.downloadLabel {
  display: inline-flex;
  text-decoration: none;
  text-transform: uppercase;
  font-size: .85rem;
  font-weight: 500;
  color: #2979ff;
  cursor: pointer;
}

.downloadIcon {
  color: #1976d2;
  margin-right: .5rem;
}

.captionText {
  color: #2979ff;
  text-transform: uppercase;
  font-size: .85rem;
  font-weight: 500;
}