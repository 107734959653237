.text {
  display: inline-flex;
  font-size: .85rem;
  color: #444444;
}

.infoWrapper {
  display: flex;
  align-items: center;
}

.infoWrapper>*:last-child {
  margin-left: .5rem;
}

.info {
  display: flex;
  align-items: center;
  font-size: .75rem;
  color: #888888;
  margin-top: 1rem;
}

.info>svg {
  font-size: 0.75rem;
  margin-right: 0.3rem;
}

.icon {
  font-size: 1.25rem;
  margin: auto .25rem;
  color: #099854;
}