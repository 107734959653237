.labelsWrapper {
  margin-top: 1.5rem
}

.labelWrapper+.labelWrapper {
  margin-top: .5rem;
}

.labelWrapper {
  height: 1.35rem;
  display: flex;
  align-items: center;
}

.color {
  width: .5rem;
  border-radius: 5px;
  height: 100%;
  margin-right: .5rem;
}

.label {
  flex-grow: 1;
  color: #333333;
  font-size: .85rem;
}

.value {
  font-size: .85rem;
  font-weight: bold;
  color: #333333;
}