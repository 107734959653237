.titleWrapper {
  display: flex;
  align-items: center;
}

.verificationIconWrapper {
  margin: 0 .75rem;
}

.headerActionsWrapper {
  display: flex;
  align-items: center;
}

.headerActionsWrapper>div+div {
  margin-left: 1rem;
}

.hqLabelWrapper, .confirmedMatchLabelWrapper, .potentialMatchLabelWrapper {
  margin-left: .75rem;
}

.lastUpdateWrapper {
  color: #BDBDBD;
  padding: .5rem;
}
