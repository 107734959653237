.mainWrapper {
  padding: 1rem;
}

.csHeaderWrapper {
  display: flex;
  align-items: center;
}

.csHeaderMessageWrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  border-radius: 5px;
  background-color: #eeeeee;
  padding: 1rem 1.5rem;
  max-width: 80%;
}

.csHeaderMessageWrapper>span {
  flex-grow: 1;
  margin-right: 1.5rem;
  display: block;
}

.csHeaderMessageWrapper {
  display: flex;
  align-items: center;
}

.statusWrapper {
  display: flex;
  align-items: center;
}

.statusWrapper>span {
  margin-right: 1rem;
  display: block;
  font-weight: 500;
}

.csFooterWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.csFooterContent {
  display: flex;
}

.csFooterTitle {
  display: inline-block;
  margin-right: .5rem;
  color: #555555;
}

.csFooterDescription {
  display: inline-block;
  margin-right: .5rem;
  font-weight: 500;
}

.filesListWrapper {
  margin-top: .5rem;
  display: flex;
  align-items: center;
}

.filesListWrapper>div {
  display: flex;
  align-items: center;
  margin: 0 .5rem .5rem 0;
}

.filesListTitle {
  display: block;
  font-weight: 500;
  font-size: .85rem;
}

.csContentWrapper {
  margin-top: 1.5rem;
}

.actionButtons {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.actionButtons>div:first-child {
  max-width: 80%;
}

.coreStandardInformationText {
  font-size: .85rem;
}

.coreStandardLabel {
  text-transform: capitalize;
}

.csHeaderActionWrapper {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.csDisclaimerWrapper {
  margin-bottom: 1.5rem;
}

.emptyLabel {
  display: block;
  padding: 5rem;
  color: #777777;
  text-align: center;
}

.commentWrapper {
  display: flex;
  margin-bottom: .5rem;
  justify-content: flex-end;
}

.commentWrapper>div:first-child {
  width: 30rem;
}

.commentWrapper>div:last-child {
  margin-left: .75rem;
  padding-top: .5rem;
}