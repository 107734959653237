.boxSection {
  padding: 1rem 1.25rem;
  background-color: #f4f4f4;
  border-radius: 5px;
  font-size: .85rem;
  color: #333333;
}

.boxSection + .boxSection {
  border-top: solid 1px #dddddd;
}

.noteWrapper {
  display: flex;
  justify-content: flex-end;
  font-size: .75rem;
}

.resultWrapper {
  display: flex;
  justify-content: flex-end;
}

.checkWrapper {
  display: inline-flex;
}

.checkInfoWrapper {
  display: flex;
  flex-direction: column;
}

.checkInfo {
  color: #9e9e9e;
}

.linkableInfo {
  text-decoration: underline;
  color: #6B5CA5;
  cursor: pointer;
}