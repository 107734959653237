.itemWrapper 
{ 
  margin-top: 1rem;
}

.itemLabel {
  font-size: 1rem;
  color: #444444;
}

.itemValue {
  display: block;
  font-size: .75rem;
  color: #888888;
}