.sectionsWrapper {
  border-radius: 5px;
  background: #f8f8f8;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
}

.sectionsWrapper>div {
  padding: 1.25rem 1.5rem;
}

.sectionsWrapper>div+div {
  border-top: solid 1px #dddddd;
}