.caption {
    color: rgba(9, 10, 10, .4);
}

.toCapitalize {
    text-transform: capitalize;
}

.attachmentName {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 10rem;
    display: flex;
}

.label {
    text-transform: capitalize;
}