.iconWrapper {
  display: flex;
  align-items: center;
}

.iconWrapper > span:nth-child(2) {
  display: inline-block;
  margin: 0 .5rem;
}

.message {
  border-radius: 5px;
  background-color: #eeeeee;
  padding: .25rem .5rem;
  font-size: .75rem;
  font-weight: 500;
  color: #444444;
}