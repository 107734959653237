.stepsWrapper {
  display: flex;
  width: 80%;
  margin: 1rem auto;
  max-width: 350px;
}

.stepWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 4rem;
}

.stepIconWrapper {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #efefef;
  border-radius: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px transparent;
  margin-bottom: .75rem;
  position: relative;
}

.stepDivider {
  flex-grow: 1;
  border-top: solid 2px #efefef;
  margin-top: calc(1.25rem - 2px);
}

.stepText {
  font-size: .8rem;
  text-align: center;
}

.bulletWrapper {
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: #efefef;
  border-radius: 50rem;
  right: .05rem;
  bottom: .05rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textWrapper {
  margin-top: .5rem;
  display: inline-block;
  background-color: #efefef;
  padding: .55rem .65rem;
  border-radius: 5px;
  color: #333333;
  font-size: .75rem;
}

.footerWrapper {
  margin-top: 1.25rem;
  display: flex;
}

.footerWrapper>div:first-child {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.footerWrapper>div:last-child {
  display: flex;
  align-items: flex-end;
}

.verifiedByLabel {
  color: #444444;
  font-size: .65rem;
  display: block;
  display: inline-block;
  margin-right: .25rem;
}

.verifiedByName {
  padding-right: .5rem;
  margin-right: .5rem;
  display: inline-block;
  margin-top: .02rem;
  font-size: .7rem;
  border-right: solid 1px #dddddd;
}

.verifiedByDate {
  display: inline-block;
  margin-top: .02rem;
  font-size: .7rem;
}

.notVerified {
  display: inline-block;
  font-size: .8rem;
  color: #444444;
}

.verifyHqWrapper>div:first-child {
  display: flex;
  align-items: center;
}

.verifyHqWrapper>div:first-child>span {
  font-weight: 500;
  display: inline-block;
  margin-left: 1rem;
  font-size: .85rem;
  line-height: 1.25rem;
}

.verifyHqWrapper>div:last-child {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.warningIcon {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 8px;
  background-color: #dd000022;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .75;
}

.combinedIcons {
  display: flex;
}

.combinedIcons>svg:first-child {
  position: relative;
}

.combinedIcons>svg:last-child {
  position: absolute;
  background: #efefef;
  border-radius: 50%;
  bottom: 0.25rem;
  right: 0.4rem;
  width: 0.75rem;
  height: 0.75rem;
}