.title {
  font-weight: 600;
  margin-bottom: 1rem;
}

.mainWrapper {
  text-align: center;
}

.subMainWrapper {
  display: flex;
  justify-content: space-between;
  text-align: center;
  width: 80%;
}

.cellHeader {
  font-weight: 500;
}

.tableWrapper {
  margin-top: 1rem;
}