.sectionTitle {
  display: block;
  font-weight: 500;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
}

.locationWrapper {
  display: flex;
}

.newLocationSearchWrapper {
  margin-right: 1rem;
}

.newLocationMapWrapper {
  width: 100%;
}

.newLocationMissingCountry {
  color: #555555;
  font-size: .85rem;
}

.additionalItemWrapper {
  display: flex;
}

.additionalItemWrapper>div:first-child {
  flex-grow: 1;
  margin-right: 1.5rem;
}

.boxSection {
  padding: 1rem;
  background-color: #f7f7f7;
  text-align: center;
}

.boxSection+.boxSection {
  border-top: solid 1px #dddddd;
}