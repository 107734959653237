.questionWrapper {
  padding: 1rem;
  border-radius: 5px;
  background-color: #fcfcfc;
  border: solid 1px #dddddd;
  width: 100%;
}

.questionContainer {
  margin-top: 2rem;
  width: 100%;
}

.questionOptionWrapper {
  margin-bottom: 1rem;
  display: flex;
}

.badge {
  width: 100%;
}

.errorMessage {
  color: #d32f2f;
  padding-left: .2rem;
}
