.tableWrapper {
  padding-top: 1rem;
}

.loaderWrapper {
  min-height: 19rem;
  align-items: center;
  display: flex;
  align-content: center;
  justify-content: center;
}

.duplicatesInfoWrapper {
  display: flex;
  justify-content: space-between;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

.infoDetailsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  min-width: 40rem;
  margin-bottom: 1rem;
  background-color: #f8f8f8;
  border-style: solid;
  border-radius: 12px;
  border-color: #dddddd;
  border-width: 0.1rem;
}

.similaritiesArea {
  background-color: #f8f8f8;
  border-style: solid;
  border-color: #dddddd;
  border-width: 0.1rem 0rem 0.1rem 0rem;
  padding: 12px;
}
.detailsTable {
  padding: 1rem;
}
.similarityHeadingsWrapper {
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
}

.detailsTable td {
  border-left: 2px solid transparent;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent; /* Adjust the spacing by changing the border size */
  display: table-cell;
  vertical-align: middle; /* Vertically center the content */
}

.detailsTable th {
  padding: 10px;

  /* Additional styling options */
  text-align: left; /* Align text to the left; */
  background-color: #f0f0f0; /* Light grey background*/
  color: #333; /* Dark text color for contrast */
}

.singleSimilarity {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.similarityResultWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

.similarityResultInnerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
