.mainWrapper {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  padding: 15px 14px;
}

.footerWrapper {
  display: flex;
  margin-left: 14px;
}

.footerWrapper>div:first-child {
  flex-grow: 1;
}

.tooltipWrapper {
  margin-right: .5rem;
  display: flex;
  align-items: center;
}

.itemWrapper {
  margin-bottom: .5rem;
}

.inputWrapper {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.buttonWrapper {
  margin-left: 1rem;
}

.noData {
  color: #777777;
}

.emptyLabel {
  display: block;
  margin-bottom: .5rem;
  color: #555555;
}