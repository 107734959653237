.headerWrapper {
  display: block;
}

.headerTitle {
  display: block;
}

.lastUpdateWrapper {
  font-size: .85rem;
  font-weight: normal;
  margin-top: .25rem;
  color: #444444;
}

.itemsWrapper > div {
  padding: .5rem;
  display: flex;
}

.itemsWrapper > div > span:first-child {
  flex-grow: 1;
  font-weight: 500;
}

.itemsWrapper > *:nth-child(odd) {
  background-color: #f8f8f8;
  border-radius: 4px;
}

.link {
  color: #2196f3;
  cursor: pointer;
  text-decoration: none;
}

.subitemLabel {
  font-weight: 500;
}

.subitemWrapper {
  flex-grow: 1;
}

.subitemWrapper > div {
  display: flex;
  padding: .5rem 0;
  flex-grow: 1;
}

.subitemWrapper > div > span:first-child {
  flex-grow: 1;
}

.itemsWrapper > div > span:first-child {
  flex-grow: 1;
  font-weight: 500;
}