.filtersWrapperExpanded {
  padding: 1rem;
  border-bottom: solid 1px #e0e0e0;
  background-color: #f8f8f8;
}

.filtersWrapperNotExpanded {
  display: none;
}

.progressWrapper {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerWrapper {
  display: flex;
  align-items: center;
  height: 3.5rem;
  padding: 0 1rem;
  border-bottom: solid 1px #e0e0e0;
}

.titleWrapper {
  flex-grow: 1;
}

.actionsWrapper {
  margin-left: 1rem;
  display: flex;
}

.actionsWrapper > * + * {
  margin-left: 1rem !important;
}

.filterIconWrapper {
  padding: 0 0.55rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  border-radius: 5px;
  margin-left: 1rem;
}

.filterIconWrapper > * + * {
  margin-left: 0.25rem;
}

.filterClearIconWrapper {
  padding: 0 0.55rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  border-radius: 5px;
  margin-left: 1rem;
  position: relative;
  cursor: pointer;
}

.filterActiveBullet {
  position: absolute;
  background-color: #ff0000;
  border-radius: 50px;
  width: 10px;
  height: 10px;
  top: -2px;
  right: -2px;
  box-shadow: 0 1px 1px 0 #00000022;
  cursor: pointer;
}

.borderedTable {
  border: solid 1px rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  width: 100%;
  margin-bottom: 0px;
  background-color: #f8f8f8;
}

.expandSectionWrapper {
  padding: 2rem;
  background-color: #f8f8f8;
}

.expandSectionItemTitle {
  color: #777777;
  font-size: 0.75rem;
  display: block;
}

.expandSectionItemValue {
  display: block;
  font-size: 0.9rem;
}

.secondaryColumns {
  border-left: 1px solid rgba(224, 224, 224, 1) !important;
  border-right: 1px solid rgba(224, 224, 224, 1);
}

.secondaryColumns:last-child {
  border-right: 0px solid rgba(224, 224, 224, 1);
}
