.totalItemWrapper+.totalItemWrapper {
  margin-top: .5rem;
}

.totalItemWrapper {
  height: 1.35rem;
}

.progressWrapper {
  border-radius: 5px;
  background-color: #f8f8f8;
  height: 1.35rem;
  display: flex;
  align-items: center;
  position: relative;
}

.progressPercentage {
  border-radius: 5px;
  background-color: #dcc0e4;
  position: absolute;
  height: 1.35rem;
}

.percentageLabel {
  color: #333333;
  position: absolute;
  font-size: .75rem;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 .5rem;
}

.percentageLabel>span:first-child {
  flex-grow: 1;
  font-weight: bold;
}