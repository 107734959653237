.label {
  display: block;
}

.requiredLabel {
  display: block;
  color: #d32f2f;
}

.mainActionWrapper {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.firstQuestionFilesListWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 0.5rem;
  margin-top: 1rem;
}

.firstQuestionFilesListWrapper>div {
  margin: 0 .5rem .5rem 0;
}