.mainWrapper {
  margin-top: 1.5rem
}

.rowWrapper+.rowWrapper {
  margin-top: .5rem;
}

.rowWrapper {
  height: 1.35rem;
  display: flex;
  align-items: center;
}

.rowWrapper>div:first-child {
  width: .5rem;
  height: 100%;
  margin-right: .5rem;
}

.rowWrapper>div:first-child>div {
  width: 100%;
  border-radius: 5px;
  height: 100%;
}

.rowWrapper>div:nth-child(2) {
  flex-grow: 1;
  color: #333333;
  font-size: .85rem;
}

.rowWrapper>div:last-child {
  font-size: .85rem;
  font-weight: bold;
  color: #333333;
}