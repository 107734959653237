.title {
  font-size: 1.3rem;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalActionsWrapper {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.modalActionsWrapper>div {
  margin-left: .5rem;
}

.caption {
  font-size: 1rem;
  color: #444444;
  margin-left: .5rem;
}