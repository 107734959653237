.titleWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.title {
  font-weight: 500;
  flex-grow: 1;
}

.icon {
  margin-right: .5rem;
}
