.contentWrapper {
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 1rem;
    margin: 2rem 0;
}

.headerWrapper {
    display: flex;
    align-items: center;
}

.headerContent {
    display: flex;
    flex-grow: 1;
}

.buttonWrapper {
    display: flex;
}

.buttonWrapper div {
    margin: .5rem;
}

.description {
    display: "flex";
    flex-direction: "column"
}

.commentFormWrapper {
    display: flex;
    margin: .5rem auto;
    justify-content: space-between;
}

.commentWrapper {
    background-color: #F8F8F8;
    padding: 1rem;
    max-width: 100%;
    border-radius: .5rem;
    margin: 1rem auto;
}

.commentContentWrapper {
    display: flex;
}

.commentInput {
    flex-grow: 1;
    margin-right: .5rem;
}

.commentText {
    background-color: #FFFFFF;
    padding: 1rem;
    border-radius: .5rem;
    font-weight: 500;
}

.commentAuthorWrapper {
    display: flex;
    margin: .5rem auto 1.5rem;
}

.commentAuthorIcon {
    height: 50px;
    width: 50px;
    line-height: 50px;
    border-radius: 50%;
    background-color: #2196f3;
    color: #FFFFFF;
    text-align: center;
    margin: auto 0.5rem auto auto;
}

.commentAuthorName {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 1rem;
}

.commentAuthorCaption {
    color: #888888;
}

.userIcon {
    height: 35px;
    width: 35px;
    line-height: 35px;
    border-radius: 50%;
    background-color: #004c88;
    color: #FFFFFF;
    text-align: center;
    margin: auto 0.5rem auto auto;
}

.replyWrapper {
    display: flex;
    margin: .5rem auto;
}

.replyText {
    background-color: #FFFFFF;
    padding: .5rem;
    border-radius: .5rem;
}

.toCapitalize {
    text-transform: capitalize;
}