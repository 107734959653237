.sectionTitle {
  font-weight: 500;
  font-size: 1.1rem;
  display: block;
  color: #333333;
  margin-bottom: 1rem;
}

.sectionWrapper {
  padding: 0 .75rem;
}

.subSectionWrapper {
  padding: 0 0 0 .75rem;
}

.sectionWrapper+.sectionTitle {
  margin-top: 2rem;
}

.questionWrapper {
  display: flex;
}

.questionWrapper+.questionWrapper {
  margin-top: 1.5rem;
}

.questionWrapper>div:first-child {
  flex-grow: 1;
}

.questionWrapper>div:last-child {
  margin-left: 3rem;
  display: flex;
  justify-content: flex-end;
}

.agencyRatingWrapper {
  margin-left: 3rem;
  width: 300px;
  display: block !important;
}

.agencyBeneficiariesWrapper {
  margin-left: 3rem;
  width: 300px;
  display: flex;
  justify-content: flex-end;
}

.questionTitle {
  display: block;
  font-weight: 500;
  color: #333333;
}

.questionDescription {
  color: #555555;
  margin-top: .5rem;
  font-size: .85rem;
  display: block;
}

.linksWrapper {
  margin-top: 1.5rem;
}

.linkItemWrapper {
  margin: .5rem 0;
  display: flex;
  align-items: center;
}

.linkItemWrapper>a {
  color: #777777;
  font-size: .85rem;
}

.linkItemWrapper>*:last-child {
  margin-left: .5rem;
}

.firstQuestionUploadFormWrapper {
  margin-top: 1.5rem;
  padding-left: .75rem;
}

.firstQuestionUploadWrapper {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.firstQuestionFormWrapper {
  margin-top: 1.5rem;
  display: flex;
}

.firstQuestionFormWrapper>div {
  width: 30%;
}

.firstQuestionFormWrapper>div+div {
  margin-left: .75rem;
}

.firstQuestionFilesListWrapper {
  display: flex;
  margin-bottom: .5rem;
}

.firstQuestionFilesListWrapper>div {
  margin: 0 .5rem .5rem 0;
}

.mainActionWrapper {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.secondQuestionWrapper {
  margin: 1.5rem auto 1.5rem 2rem;
}

.secondQuestionUploadWrapper {
  margin-top: .5rem;
}

.subQuestionWrapper {
  margin-top: 1.5rem;
  padding-left: 1rem;
  display: flex;
}

.subQuestionWrapper>div:first-child {
  flex-grow: 1;
}

.secondQuestionSubWrapper {
  padding-left: 2rem;
}

.disclaimerText {
  display: block;
  margin-top: .5rem;
  color: #333333;
  font-size: .9rem;
}

.warningIcon {
  width: 1.35rem;
  height: 1.35rem;
  border-radius: 8px;
  background-color: #dd000022;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .75;
  margin-left: 1rem;
}

.copyUploadedWrapper {
  margin-bottom: 1rem;
  width: 100%;
}

.differentRatingCommentWrapper{
  width: 500px;
  margin-top: 1rem;
}