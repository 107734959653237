.mainWrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
}

.contentWrapper {
  width: 400px;
  height: 300px;
}

.actionsWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0 8px 8px 8px;
}