.mainWrapper {
  border-radius: 5px;
  overflow: hidden;
  border: solid 1px #dddddd;
  background-color: #f4f4f4;
}

.sectionWrapper+.sectionWrapper {
  border-top: solid 1px #dddddd;
}

.sectionTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .75rem 1rem .75rem 1.5rem;
}

.sectionTitle {
  font-size: 1.05rem;
  font-weight: 500;
}

.sectionBodyWrapper {
  padding: 1rem 2.25rem;
}

.sectionItemWrapper+.sectionItemWrapper {
  margin-top: 1.5rem;
}

.ratingText {
  display: block;
  margin-bottom: .15rem;
}

.dateText {
  display: block;
  font-size: .9rem;
  color: #555555;
}

.revisionsWrapper {
  padding: 1rem 1.5rem;
}

.revisionsWrapper>tr>td {
  padding: .25rem 1.5rem .25rem 0;
}

.notEligible {
  font-weight: 500;
  color: #dd0000;
  border-radius: 8px;
  background-color: #dd000014;
  padding: .2rem .45rem;
  font-size: .9rem;
}

.emptyLabel {
  color: #777777;
}

.justificationWrapper {
  margin-top: 1.5rem;
}

.justificationWrapper>span {
  display: block;
  margin-bottom: .5rem;
}

.expiredLabel {
  display: inline-block;
  color: #dd0000;
  margin-left: .5rem;
  font-weight: 500;
}

.tooltipWrapper {
  margin-left: .5rem;
  display: inline-flex;
  align-items: center;
}

.justificationHeader {
  font-weight: 500;
  text-align: center;
}

.ratingCommentsListWrapper {
  display: flex;
  margin-top: 1.5rem;
  max-width: 800px;
  padding-left: 1.5rem;
}

.ratingCommentsListWrapper>div:first-child {
  flex-grow: 1;
}

.ratingCommentsTitle {
  display: block;
  font-weight: 500;
  margin-bottom: 1rem;
}

.ratingCommentItemWrapper {
  margin-top: 1.5rem;
  padding-left: 1.5rem;
}

.ratingCommentItemWrapper>span {
  display: block;
  margin-bottom: .5rem;
}

.ratingCommentItemWrapper>pre {
  display: block;
  margin-bottom: .5rem;
  font-family: inherit;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 600px;
}

.justificationToProceedTitle {
  font-weight: 500;
}

.justificationToProceedText {
  font-family: inherit;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 600px;
}

.noRatingComments {
  display: block;
  font-size: .9rem;
  color: #555555;
  padding-left: 1.5rem;
}