.mainWrapper {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  padding: 15px 14px;
  display: flex;
  align-items: center;
  height: fit-content;
  min-height: 1.4375em;
}

.filesAndLabelWrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  width: calc(100% - 140px);
  box-sizing: border-box;
}

.buttonWrapper {
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.fileInput {
  display: none;
}

.placeholder {
  font-size: .95rem;
}

.label {
  display: inline-block;
  margin-right: 1rem;
  font-weight: 500;
  font-size: .9rem;
  white-space: nowrap;
}

.attachmentName span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 10rem;
}

.filesListWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  min-width: 0;
}

.filesListWrapper>.fileWrapper+.fileWrapper {
  margin-left: .5rem;
  padding-bottom: .1rem;
}

.fileWrapper {
  max-width: 100%;
}

.tooltipWrapper {
  margin-right: .5rem;
}